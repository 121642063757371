<template>
  <div class="streamroomManagement">
    <div class="header">
      <h2>Active Stream Rooms</h2>

      <div class="actions">
        <button
          id="joinStreamRoom"
          class="button"
          @click.prevent="goToStream()"
        >
          Join
        </button>
        <button
          id="deleteStreamRoom"
          class="button"
          v-if="this.$store.state.userRole == 'admin'"
          @click.prevent="selectAction($event)"
          :class="{
            active:
              modalMode == 'deleteStreamRoomModal' &&
              StreamRoomModalActive == true,
          }"
        >
          Delete
        </button>

        <button
          id="editStreamRoom"
          class="button"
          v-if="this.$store.state.userRole == 'admin'"
          @click.prevent="selectAction($event)"
          :class="{
            active:
              modalMode == 'editStreamRoomModal' &&
              StreamRoomModalActive == true,
          }"
        >
          Edit
        </button>
        <button
          id="createStreamRoom"
          class="button"
          v-if="this.$store.state.userRole == 'admin'"
          @click.prevent="selectAction($event)"
          :class="{
            active:
              modalMode == 'createStreamRoomModal' &&
              StreamRoomModalActive == true,
          }"
        >
          Create
        </button>
                <button
          id="refreshList"
          class="button"
          @click.prevent="getAllStreamRooms()"
        >
              <font-awesome-icon :icon="['fas', 'arrow-rotate-right']" />

        </button>
      </div>
    </div>

    <div class="modalBody">
      <div class="datawrapper">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark datatable"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :rowSelection="rowSelection"
          :defaultColDef="defaultColDef"
          :detailCellRendererParams="detailCellRendererParams"
          @grid-ready="onGridReady"
          @grid-size-changed="sizeToFit"
          @selection-changed="onSelectionChanged"
          :sortingOrder="sortingOrder"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
  <transition appear name="slide-fade">
    <component
      :streamRoomId="streamRoomId"
      :is="modalMode"
      v-if="StreamRoomModalActive"
      v-on:close-modal="closeStreamRoomModal"
    />
  </transition>
</template>

<script>
import { defineAsyncComponent, reactive } from "vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { AgGridVue } from "ag-grid-vue3";
// const AgGridVue = () => import("~/components/MyComponent.js");
// import createStreamRoomModal from "./CreateStreamRoomModal.vue";
// import deleteStreamRoomModal from "./DeleteStreamRoomModal.vue";
// import editStreamRoomModal from "./EditStreamRoomModal.vue";
//import firebase from "firebase/compat/app";
import { timestamp, auth, rtdb, db } from "../firebase/firebaseInit.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

const createStreamRoomModal = defineAsyncComponent(() =>
  import("./CreateStreamRoomModal.vue")
);
const deleteStreamRoomModal = defineAsyncComponent(() =>
  import("./DeleteStreamRoomModal.vue")
);
const editStreamRoomModal = defineAsyncComponent(() =>
  import("./EditStreamRoomModal.vue")
);


window.disableUserFormatter = function disableUserFormatter(params) {
  if (params.value == true) {
    return "Disabled";
  } else {
    return "Enabled";
  }
};

export default {
  data() {
    return {
      columnDefs: null,
      rowData: [],
      rowSelection: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: { resizable: true },
      modalMode: "createStreamRoomModal",
      StreamRoomModalActive: false,
      inviteCreateStreamRoomModalActive: false,
      sortingOrder: null,
      pagination: null,
      paginationPageSize: null,
      streamRoomId: null,
      detailCellRendererParams: null,
    };
  },
  components: {
    AgGridVue,
    createStreamRoomModal,
    editStreamRoomModal,
    deleteStreamRoomModal,
    FontAwesomeIcon
  },
  methods: {
    goToStream() {
      let nextroute = this.streamRoomId;
      if (this.streamRoomId == this.$store.state.targetStream) {
        this.$router.push({ name: "Stream Room", query: { rid: nextroute } });
        // console.log(this.streamRoomId + " " + this.$store.state.targetStream);
      }
    },
    selectAction(event) {
      console.log(this.modalMode);
      if (
        (event.currentTarget.id !== "createStreamRoom" &&
          this.streamRoomId == null) ||
        this.streamRoomId == ""
      ) {
        return;
      } else if (this.modalMode == event.currentTarget.id + "Modal") {
        this.StreamRoomModalActive = !this.StreamRoomModalActive;
      } else {
        this.modalMode = event.currentTarget.id + "Modal";
        this.StreamRoomModalActive = true;
      }
      console.log(this.modalMode);
    },
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.streamRoomId =
        selectedRows.length === 1 ? selectedRows[0].roomId : "";
      // console.log(this.streamRoomId);
      // this.$store.state.targetStream = this.streamRoomId;
      this.$store.commit("targetStream", this.streamRoomId);
      // console.log("state: " + this.$store.state.targetStream);
    },
    async getAllStreamRooms() {
      // console.log("TRIGGERED")
      let claims = this.$store.state.userClaims;
      this.rowData = []

      if (claims.admin) {
        db.collection("streamRooms")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              //    console.log(doc.id, "=>", doc.data());
              this.rowData.push(doc.data());
              //    console.log(this.rowData);
            });
          });
      } else if (claims.viewer || claims.broadcaster) {
        db.collection("users")
          .doc(this.$store.state.profileId)
          .collection("roomAccess")
          .get()
          .then(async (querySnapshot) => {
            let dataBuffer = [];
            await querySnapshot.forEach((doc) => {
              db.collection("streamRooms")
                .doc(doc.id)
                .get()
                .then((doc) => {
                  dataBuffer.push(doc.data());
                  this.gridApi.setRowData(dataBuffer)
                });
            });
          });
      }
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    // openCreateStreamRoomModal() {
    //   this.modalMode = createStreamRoomModal;
    //   this.StreamRoomModalActive = true;
    // },
    // openEditStreamRoomModal() {
    //   if (this.uid == null || this.uid == "") {
    //     return;
    //   } else {
    //     this.modalMode = editStreamRoomModal;
    //     this.StreamRoomModalActive = true;
    //   }
    // },
    closeStreamRoomModal() {
      this.rowData = [];
      this.getAllStreamRooms();
      this.StreamRoomModalActive = !this.StreamRoomModalActive;
    },
  },
  created() {
    this.detailCellRendererParams = {
      refreshStrategy: 'rows',
    }
    this.getAllStreamRooms();
    this.rowSelection = "single";
    this.sortingOrder = ["desc", "asc", null];
    // enables pagination in the grid
    this.pagination = true;
    // sets 10 rows per page (default is 100)
    this.paginationPageSize = 10;
    this.defaultColDef = {
      resizable: true,
    };
  },
  mounted() {
    this.columnDefs = [
      {
        field: "roomName",
        filter: true,
        floatingFilter: true,
        minwidth: 120,
        sort: "asc",
        sortable: true,
      },
      {
        field: "projectName",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      {
        field: "creatorEmail",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      {
        field: "streamLive",
        filter: true,
        floatingFilter: true,
        width: 120,
        sortable: true,
      },
      // { field: "uid", filter: true, floatingFilter: true },
    ];
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.streamroomManagement {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 2;
  // width: 100%;
  max-width: 800px;
  background-color: #262830;
  height: 600px;
  transition: all 0.5s ease-out;
}

.header {
  background-color: RGB(47, 50, 60);
  height: 70px;
  display: flex;
  align-items: center;
  color: #e5ecff;
  font-size: 1.5em;
  font-weight: 700;
  padding-left: 25px;

  h2 {
    font-size: 24px;
    font-weight: 300;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: auto;
  margin-right: 30px;

  .button {
    position: relative;
    padding: 0px 15px;
    max-height: 37px;
    min-height: 33px;
    font-size: 12px;
    font-weight: 400;
    background-color: RGBA(68, 75, 90, 0.2);
    border: 1px solid #444b5a;
    color: inherit;
    cursor: pointer;
    min-width: 33px;
    margin-left: 10px;
  }

  #deleteStreamRoom {
    background-color: RGBA(233, 60, 45, 0.2);
    border: 1px solid RGB(233, 60, 45);
  }

  #deleteStreamRoom:hover {
    background-color: RGB(233, 60, 45, 0.8);
  }

  .button:hover {
    background-color: #505874;
  }
  .active {
    background-color: RGBA(239, 243, 255, 0.05) !important;
    border-color: RGB(239, 243, 255) !important;
  }
}

.modalBody {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 15px 15px;
}

.datawrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;

  .datatable {
    width: calc(100% - 30px);
    height: calc(100% - 10px);
    border: 1px solid #000000;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
